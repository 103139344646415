<template>
<div style="width:100%">
  <v-card>
    <v-toolbar :color="colores.primario" dark dense>
      <v-toolbar-title>CONTACTO</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <texto2 :colores="colores" :titulo="'IDENTIFICACION'" :descripcion="gescontacto.identificacion"></texto2>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete v-model="contacto.documento_id" :rules="[rules.requerido]" :loading="loadingForm" :items="items.items_documentos" item-text="descripcion" item-value="id" :color="colores.secundario" label='* TIPO DE DOCUMENTO' autocomplete="off"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-text-field v-model="contacto.nombres" :rules="[rules.requerido]" label='* NOMBRES' autocomplete="off" :color="colores.secundario" maxlength="40" counter="40"></v-text-field>
        </v-col>
        <v-col cols="12" md="3" class="pr-1">
            <v-text-field v-model="contacto.apellido1" :rules="[rules.requerido]" label='* PRIMER APELLIDO' autocomplete="off" :color="colores.secundario" maxlength="30" counter="30"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field v-model="contacto.apellido2" label='SEGUNDO APELLIDO' autocomplete="off" :color="colores.secundario" maxlength="30" counter="30"></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-text-field v-model="contacto.empresa" label='EMPRESA' autocomplete="off" :color="colores.secundario" maxlength="50" counter="50"></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
            <v-text-field v-model="contacto.cargo" label='CARGO' autocomplete="off" :color="colores.secundario" maxlength="50" counter="50"></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-text-field v-model="contacto.telefono" label='TELEFONO' autocomplete="off" :color="colores.secundario" maxlength="10" counter="10"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pr-1">
          <v-text-field v-model="contacto.celular" label='CELULAR' autocomplete="off" :color="colores.secundario" maxlength="10" counter="10"></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <texto3 :colores="colores" :titulo="'PAÍS'" :obj="gescontacto.pais"></texto3>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <texto3 :colores="colores" :titulo="'DEPARTAMENTO'" :obj="gescontacto.departamento"></texto3>
        </v-col>
        <v-col cols="12" md="6">
          <texto3 :colores="colores" :titulo="'CIUDAD'" :obj="gescontacto.ciudad"></texto3>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="gescontacto.correo">
        <v-col cols="12" md="6" class="pr-1">
          <texto2 :colores="colores" :titulo="'CORREO'" :descripcion="gescontacto.correo"></texto2>
        </v-col>
      </v-row>
      <v-row no-gutters v-else>
        <v-col cols="12" md="6" class="pr-1">
          <v-text-field v-model="contacto.correo" :rules="[rules.email]" label='CORREO' autocomplete="off" :color="colores.secundario" maxlength="100" counter="100"></v-text-field>
        </v-col>
      </v-row>

      <!-- codigo nuevos -->
      <v-row no-gutters v-if="gescontacto.direccion">
        <v-col cols="12" md="6" class="pr-1">
          <texto2 :colores="colores" :titulo="'DIRECCIÓN'" :descripcion="gescontacto.direccion"></texto2>
        </v-col>
      </v-row>
      <v-row no-gutters v-else>
        <v-col cols="12" md="6" class="pr-1">
          <v-text-field v-model="contacto.direccion" label='DIRECCIÓN' autocomplete="off" :color="colores.secundario" maxlength="200" counter="200"></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <texto3 :colores="colores" :titulo="'TIPO DE PERSONSA'" :obj="gescontacto.tipopersona"></texto3>
        </v-col>
        <v-col cols="12" md="6" class="pr-1">
          <texto3 :colores="colores" :titulo="'SEXO'" :obj="gescontacto.sexo"></texto3>
        </v-col>
      </v-row><v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <texto3 :colores="colores" :titulo="'LGTBIQ+'" :obj="gescontacto.lgbtiq"></texto3>
        </v-col>
        <v-col cols="12" md="6" class="pr-1">
          <texto3 :colores="colores" :titulo="'CONDICIÓN ESPECIAL'" :obj="gescontacto.especial"></texto3>
        </v-col>
      </v-row>

    </v-card-text>
  </v-card>
</div>
</template>

<script>
import recDepartamento from '@/js/rec_departamentos.js'
import recContactos from '@/js/rec_contactos.js'

export default {
  name: 'form_aseguradoComponent',
  components: {
    texto2: () => import('@/components/widgets/text2Component.vue'),
    texto3: () => import('@/components/widgets/text3Component.vue')
  },
  mixins: [recDepartamento, recContactos],
  props: ['colores', 'rules', 'items', 'loadingForm', 'contacto', 'gescontacto'],
  data: () => ({
    isSuccess: false,
    isloadingIden: false,
    loadingCiudad: false,
    items_ciudades: []
  }),
  created () {

  },
  methods: {
    input_pais (pPaisId) {

    },
    input_departamento (pDepartamentoId) {
      this.contacto.ciudad_id = null
      this.rec_departamento_por_id(pDepartamentoId)
    },
    blur_identificacion (pIdentificacion) {
      this.rec_contacto_por_identificacion(pIdentificacion)
    }
  }
}
</script>
